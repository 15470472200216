import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import Home from "../pages/account/Home";
import Settings from "../pages/account/Settings";
import { Layout } from "antd";
import Landingdownload from "../pages/banks/landing-download-documents/landingBanks";
import Afterbanks from "../pages/afterbanks/Afterbanks";
import AfterbanksDocumentacion from "../pages/afterbanks/AfterbanksDocumentacion";

const { Content, Sider } = Layout;

const Navigation = () => {
  return (
    <div>
      <Sider>
        <Routes>
          <Route path="dashboard" element={<Settings />} />
        </Routes>
      </Sider>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/afterbanks/:loanId/:bankCode" element={<Afterbanks />} />
        <Route
          path="/afterbanks/:idOperation/:currentOwner/:token"
          element={<AfterbanksDocumentacion />}
        />
        <Route
          path="/banks/download-operation-documents/:operationId/:bankRequestId/:token"
          element={<Landingdownload />}
        />
      </Routes>
    </div>
  );
};

export default Navigation;
