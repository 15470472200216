import React, { useEffect, useState } from "react";
import { Button, Card, Input, Layout, notification, Tooltip, Typography } from "antd";
import checkFinish from "../../../assets/checkDownload.png";
import axios from "axios";
import { useParams } from "react-router-dom";
import env from "../../../environment";
import {
  InfoCircleOutlined
} from "@ant-design/icons";

const { Text } = Typography;

function Landingdownload() {
  const [onError, setOnError] = useState(false);
  const [password, setPassword] = useState(undefined);
  const [zipUrl, setZipURl] = useState(undefined);
  const params = useParams();

  const [data, setData] = useState({ name: "", owner: "" });

  const { operationId, bankRequestId, token } = params;
  const [dataError, setDataError] = useState(false);

  useEffect(() => {
    getDataOperataion();
  }, []);

  const getDataOperataion = () => {
    axios
      .get(`${env.api.url}/v1/guest/operations/${operationId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response: any) => {
		if (response.data.success === false && response.data.message === "Expired token") {
			setDataError(true)
		}else {
			setData(response.data.data.operation);
		}
      });
  };

  const downloadFile = () => {
    sendPassword();
  };

  const sendPassword = () => {
    axios
      .post(
        `${env.api.url}/banks/zip/get-zip`,
        {
          operationId: operationId,
          bankRequestId: bankRequestId,
          password: password, //password
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response: any) => {
        // This method of check error can be used in other places!!
        const checkErrorsAndShowMessage: any = {
          ZIP_URL_ERROR_OPERATION_NOT_FOUND: () => {
            notification.error({
              message: "Operación no encontrada",
              description:
                "La operación no ha sido encontrada. Por favor, contacte con Gibobs All Banks.",
            });
          },
          ZIP_URL_ERROR_OPERATION_NOT_CORRESPOND_TO_BANK_REQUEST: () => {
            notification.error({
              message: "Operación inválida",
              description:
                "La operación no se corresponde con la oferta bancaria. Por favor, contacte con Gibobs All Banks",
            });
          },
          ZIP_URL_ERROR_PASSWORD_NOT_MATCH: () => {
            notification.error({
              message: "Error al introducir la contraseña",
              description:
                "La contraseña es inválida. Por favor, inténtelo de nuevo o contacte con Gibobs All Banks.",
            });
          },
          ZIP_URL_ERROR_OBJECT_DOEST_NOT_EXISTS: () => {
            notification.error({
              message: "Error en la descarga",
              description:
                "Los documentos no existen. Por favor, contacte con Gibobs All Banks.",
            });
          },
          default: () => {
            notification.error({
              message: "Ocurrió un error",
              description:
                "Ha ocurrido un error por favor contacte con Gibobs All Banks.",
            });
          },
        };

        if (!response.data.success && response.data.message) {
          checkErrorsAndShowMessage[response.data.message]
            ? checkErrorsAndShowMessage[response.data.message]()
            : checkErrorsAndShowMessage["default"]();
          return;
        }

        setZipURl(response.data.data.zipUrl);
        window.open(response.data.data.zipUrl);
        setOnError(true);
      })
      .catch(() => {
        notification.error({
          message: "Ocurrio un error",
          description:
            "Ha ocurrido un error. Por favor, contacte con Gibobs All Banks.",
        });
      });
  };

  const createPassword = (e: any) => {
    setPassword(e.target.value);
  };

  if (dataError) {
    return (
      <Layout className="landingDownload">
        <Card
          className="gb-card-desktop-landing"
          style={{ width: 394, height: 292, position: "absolute", top: "26%" }}
        >
          <div style={{ width: 204 }}>
            <div style={{ textAlign: "center", width: "100%" }}>
              <img
                src={
                  "https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/logos/logo-gmail.png"
                }
                style={{ height: 92, width: 204 }}
                alt="Hadmin"
              ></img>
            </div>
            {!onError && (
              <div
                style={{ marginTop: 24, textAlign: "center", marginRight: -20 }}
              >
                <Text
                  style={{ color: "#2F4858", fontSize: 16, fontWeight: 600 }}
                >
                  Tu sesión ha caducado
                </Text>
              </div>
            )}

            <div
              style={{
                display: "flex",
                marginTop: 32,
                width: "110%",
				textAlign: "center"
              }}
            >
              <Text style={{ color: "#748EA0", fontSize: 14, fontWeight: 600 }}>
                Por favor, ponte en contacto con atencion al cliente
              </Text>
            </div>
          </div>
        </Card>
      </Layout>
    );
  } else {
    return (
      <Layout className="landingDownload">
        <Card
          className="gb-card-desktop-landing"
          style={{ width: 394, height: 480, position: "absolute", top: "26%" }}
        >
          <div style={{ width: 204 }}>
            <div style={{ textAlign: "center", width: "100%" }}>
              <img
                src={
                  "https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/logos/logo-gmail.png"
                }
                style={{ height: 92, width: 204 }}
                alt="Hadmin"
              ></img>
            </div>
            {!onError && (
              <div
                style={{ marginTop: 24, textAlign: "center", marginRight: -20 }}
              >
                <Text
                  style={{ color: "#2F4858", fontSize: 16, fontWeight: 600 }}
                >
                  Los documentos están listos para su descarga
                </Text>
              </div>
            )}
            {onError && (
              <div
                style={{ marginTop: 24, textAlign: "center", width: "100%" }}
              >
                <Text
                  style={{ color: "#2F4858", fontSize: 16, fontWeight: 600 }}
                >
                  Tu descarga ha empezado
                </Text>
              </div>
            )}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 32,
              width: "100%",
            }}
          >
            <Text style={{ color: "#748EA0", fontSize: 14, fontWeight: 600 }}>
              Operación {data.name}
            </Text>
            <Text style={{ color: "#748EA0", fontSize: 14, fontWeight: 600 }}>
              {data.owner}
            </Text>
          </div>
          {!onError && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 32,
                width: "100%",
              }}
            >
              <Text style={{ color: "#2F4858", fontSize: 10, fontWeight: 400 }}>
                Contraseña
                <Tooltip title={'La contraseña es el DNI con letra del titular 1 de la operación - lo verás en el email recibido de Gibobs'}>
                <InfoCircleOutlined  style={{marginLeft: 8, fontWeight: 600}}/>
                </Tooltip>

              </Text>

              <Input
                type="password"
                placeholder="********"
                onChange={(e) => createPassword(e)}
                style={{
                  width: 234,
                  height: 32,
                  borderColor: "#C0DAEE",
                  border: "1px solid #C0DAEE",
                  borderRadius: 4,
                }}
              ></Input>
            </div>
          )}
          {!onError && (
            <div style={{ marginTop: 40 }}>
              <Button
                type="primary"
                className="button-primari-gibobs"
                onClick={() => downloadFile()}
                style={{ height: 40, width: 234, borderColor: "white" }}
              >
                Descargar documentos
              </Button>
            </div>
          )}

            {onError && (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginTop: 48,
                  marginLeft: 10,
                }}
              >
                <img
                  src={checkFinish}
                  alt="asdf"
                  style={{ width: 132, height: 132 }}
                ></img>
              </div>
            )}
          </div>
        </Card>
      </Layout>
    );
  }
}

export default Landingdownload;
