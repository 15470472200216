import { Button, Layout } from "antd";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  return (
    <div style={{ height: "100vh", width: "100%"}}>

    <div style={{textAlign: "center", position: "relative", top: "40%"}}>
      <img
        src={
          "https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/logos/logo-gmail.png"
        }
        alt="Hadmin"
      ></img></div>
    </div>
  );
};
export default Home;
