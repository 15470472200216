import React from "react";
import { Route, Routes } from "react-router-dom";
import Navigation from "./components/Navigation";
import { Layout } from "antd";
import { useNavigate } from "react-router-dom";
import "./App.less";

const App = () => {
  const navigate = useNavigate();

  return (
    <div className="pageLayout" style={{ width: "100%" }}>
      <Navigation />
    </div>
  );
};

export default App;
